const config = {
  apiGateway: {
    REGION: 'eu-west-1',
    PHY_LOCATION_APIGATEWAY_URL: 'https://6quf2kroai.execute-api.eu-west-1.amazonaws.com/prod',
    INTERNAL_PHY_LOCATION_APIGATEWAY_URL: 'https://0drws42ik8.execute-api.eu-west-1.amazonaws.com/prod',
    COMPANY_APIGATEWAY_URL: 'https://r9s7k83vxi.execute-api.eu-west-1.amazonaws.com/prod',
  },
  reactApp: {
    VERSION: 'taxonomy-physical-location-1.11.2',
    HOSTNAME: 'https://locations.staging.macquarie.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.macquarie.forwoodsafety.com',
    WEBSOCKET: 'wss://i1hb5vtuq9.execute-api.eu-west-1.amazonaws.com/staging'
  },
};

export default config;